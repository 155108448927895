<template>
  <div class="settings">
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/',false)" class="header-route-back">
          <span v-if="deviceType === 'ios'">
            <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
          <span v-else>
            <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Finans
          </p>
        </div>
      </div>
    </div>

    <div class="finance-container">
      <loader v-if="!pageLoaded"  style="margin-top: 40vh;"></loader>

      <div v-if="pageLoaded">

        <div class="finance-activities">
          <div v-for="payment in payments" class="finance-activities-item">
            <div>
              <div class="finance-activities-item-description">
                <span class="finance-activities-item-description-text">
                  {{ payment.description }}
                </span>
                <span class="finance-activities-item-description-status">
                  İşlem No: {{ payment.transaction_id }}
                </span>
                <span class="finance-activities-item-description-status">
                  {{ payment.status_text }}
                </span>
              </div>
              <div class="finance-activities-item-date">
                {{ Number(payment.created_at) | customDateFormat('dd MMM yyyy') }}
              </div>
            </div>
            <div class="finance-activities-item-total" :style="{'color': payment.total < 0 ? ' #f23434' : '#6dce6a'}">
              {{ payment.total }}
            </div>
          </div>
        </div>
        <div v-if="seemorediv && !showLoader" @click="retrievePaymentHistory()" class="see-more">
          Daha fazla gör +
        </div>
        <loader v-if="showLoader"></loader>
      </div>
    </div>

  </div>
</template>

<script>

  import WorkstationNav from "../navComponent";

  export default {
    name: "src-pages-body-workstation-finance-v1_mobile",
    components: {
      WorkstationNav
    },
    data() {
      return {
        pageLoaded: null,
        payments: [],
        seemorediv: false,
        offset: 0,
        showLoader: false
      }
    },

    methods: {
      retrievePaymentHistory() {
        this.showLoader = true;
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
        this.api.user.retrievePaymentHistory(null, this.offset)
          .then(({data}) => {
            let result = data;
            this.showLoader = false;

            if (result.success) {
              this.payments = this.payments.concat(result.data.payment_history);
              this.seemorediv = result.data.seemorediv;
              this.offset += 10;
            } else {
              this.$router.push('/');
            }
          })
          .catch(err => {
            this.$router.push('/');
          })
      },


      retrieveHistory(limit, offset) {
        this.api.user.retrievePaymentHistory(limit, offset)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.pageLoaded = true;
              this.payments = result.data.payment_history;
              this.seemorediv = result.data.seemorediv;
              this.offset += 10;
            } else {
              this.$router.push('/');
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    created() {
      this.EventBus.$on("retrieveWithdrawFunds", (payload) => {
        this.payments.unshift(payload);
      });
      this.retrieveHistory(null, this.offset);
    }
  }
</script>

<style scoped lang="scss">
  .header-title-container{
    padding-right: 18px;
  }

  .finance-container{
    padding-top: 56px;
    padding-bottom: 50px;
    background-color: #f4f5f7;
  }

  .finance-header{
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
  }

  .finance-activities{
    padding: 15px;
  }

  .finance-activities-item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 17px 15px 12px;
    background-color: #FFF;
    border: solid 1px #dfe4ec;
    margin-bottom: 10px;
  }

  .finance-header-available-cash{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #4b4f52;
  }

  .withdrawfundsModalTrigger{
    padding-top: 2px;
    font-size: 14px;
    line-height: 1.29;
    color: #2d3640;
  }

  .finance-activities-item-description{
    display: flex;
    flex-direction: column;
  }

  .finance-activities-item-description-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.12;
    color: #2d3640;
  }

  .finance-activities-item-description-status{
    padding-top: 1px;
    font-size: 13px;
    font-style: italic;
    line-height: 1.38;
    color: #8b95a1;
  }

  .finance-activities-item-date{
    padding-top: 11px;
    font-size: 13px;
    line-height: 1.38;
    text-align: left;
    color: #8b95a1;
  }

  .finance-activities-item-total{
    font-size: 16px;
    line-height: 1.12;
  }

  .see-more{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
  }

  .super-button-light-small{
    width: 100px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    padding:0;
  }

</style>
